<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="loginBackground"
            alt="Login Background"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            <span class="text-primary font-weight-bolder">Song Long</span> Dashboard 👋
          </b-card-title>
          <b-alert
            variant="primary"
            show
          >
            <div class="alert-body font-small-3">
              <p>
                Thông tin đăng nhập của bạn
              </p>
            </div>
            <feather-icon
              v-b-tooltip.hover.left="'Liên hệ Admin để lấy thông tin đăng nhập'"
              icon="HelpCircleIcon"
              size="18"
              class="position-absolute"
              style="top: 10px; right: 10px;"
            />
          </b-alert>

          <!-- form -->
          <validation-observer ref="loginForm">
            <b-form
              class="auth-login-form mt-2"
              @submit.prevent="login"
            >
              <!-- phone -->
              <form-required
                v-model="loginPhone"
                vid="phone"
                label="Số điện thoại"
                custom-v-error="Số điện thoại là bắt buộc"
                rules="required"
                @update-value="val => loginPhone = val"
              />

              <!-- mật khẩu -->
              <form-required
                v-model="loginPassword"
                vid="password"
                label="Mật khẩu"
                input-type="password"
                custom-v-error="Mật khẩu là bắt buộc"
                rules="required"
                @update-value="val => loginPassword = val"
              />
              <b-button
                type="submit"
                variant="primary"
                block
              >
                Đăng nhập
              </b-button>
            </b-form>
          </validation-observer>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import { ValidationObserver } from 'vee-validate'
import {
  BAlert, BButton, BCardTitle, BCol, BForm, BImg, BRow, VBTooltip,
} from 'bootstrap-vue'
import useJwt from '@/auth/jwt/useJwt'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'
import FormRequired from '@core/components/form-required/FormRequired.vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import searchAndBookmarkData from '@core/layouts/components/app-navbar/search-and-bookmark-data'
import { ref } from '@vue/composition-api'
import { $themeConfig } from '@themeConfig'

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BCardTitle,
    BImg,
    FormRequired,
    BForm,
    BButton,
    BAlert,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  setup() {
    const loginPhone = ref('')
    const loginPassword = ref('')
    const isRememberMe = ref('')

    const {
      appName,
      loginBackground,
    } = $themeConfig.app

    return {
      loginPhone,
      loginPassword,
      isRememberMe,
      loginBackground,
      appName,
    }
  },
  data() {
    return {
      // validation rules
      required,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    console.log('login')
  },
  methods: {
    login() {
      this.$refs.loginForm.validate()
        .then(success => {
          if (success) {
            useJwt.login({
              phone: this.loginPhone,
              password: this.loginPassword,
            })
              .then(response => {
                const userData = response.data?.data?.user

                localStorage.setItem('userData', JSON.stringify(userData))
                localStorage.setItem('bookmarksInSession', JSON.stringify(searchAndBookmarkData.pages.data.filter(page => page.isBookmarked) || []))

                // if (this.isRememberMe) {
                useJwt.setToken(response.data.data.token)
                // }

                // ? This is just for demo purpose. Don't think CASL is role based in this case, we used role in if condition just for ease

                this.$router.replace(getHomeRouteForLoggedInUser(userData.role))
                  .then(() => {
                    this.$toast({
                      component: ToastificationContent,
                      position: 'top-right',
                      props: {
                        title: `Xin chào ${userData.fullname || userData.username}!`,
                        icon: 'CoffeeIcon',
                        variant: 'success',
                        text: `Chúc ${userData.fullname || userData.username} làm việc hiệu quả`,
                      },
                    })
                  })
              })
              .catch(err => {
                let error = err?.response?.data?.message
                error = error || err?.response?.data?.error?.message || err.message || err
                this.$toast({
                  component: ToastificationContent,
                  position: 'top-right',
                  props: {
                    title: `${error}`,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
                this.$refs.loginForm.setErrors({
                  phone: 'custom-error:',
                  password: 'custom-error:',
                })
              })
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import 'src/@core/scss/vue/pages/page-auth.scss';
</style>
